<template>
  <div class="success">
    <div class="payimg">
      <img src="../../assets/success.png" alt="" />
      <div class="paysuccess">支付成功</div>
    </div>
    <van-divider style="margin-top: 70px" />
    <div class="submit">
      <van-button round type="info" style="width: 100%" @click="openInvoice" v-if="isShow">申请发票</van-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Success",
  data() {
    return {
      isShow: false,
      domain: process.env.VUE_APP_DOMAIN,
    };
  },
  mounted() {
    let appId = localStorage.getItem("appId");
    let itemId = localStorage.getItem("itemId");
    this.$axios
      .post(`${this.domain}/applet/findH5InvoiceConfig`, {
        itemId,
        appId,
      })
      .then((res) => {
        this.isShow = JSON.parse(res.data.data.value);
      });
  },
  methods: {
    openInvoice() {
      this.$router.push({
        path: "/paysuccess/invoice",
        query: { orderIds: this.$route.query.orderIds, plateNo: this.$route.query.plateNo },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.success {
  .payimg {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
    width: 100px;
    height: 100px;
    img {
      width: 100px;
      height: 100px;
    }

    .paysuccess {
      margin: 20px 0;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .submit {
    box-sizing: border-box;
    padding: 0 20px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
